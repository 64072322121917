<template>
  <div class="min-h-screen hero bg-base-200">
    <div class="text-center hero-content">
      <div class="max-w-md">
        <div class="flex items-center h-16 mb-5 space-x-3">
          <img
            src="@/assets/capacio/capacio-log-black.png"

            class="h-12 p-2 rounded-lg "
          > <h1
            class="text-2xl"
            v-if="selectedLang === 'EN'"
          >
            Team Rating
          </h1>
          <h1
            class="text-2xl"
            v-else
          >
            Gruppskattningsformulär
          </h1>
        </div>

        <!-- <div>
          <h5
            class="leading-8"
            v-if="selectedLang === 'EN'"
          >
            Select questionnaire language
          </h5>
          <h5
            v-else
            class="leading-8"
          >
            Välj språk på frågeformuläret
          </h5>
          <div class="flex justify-center my-8 space-x-6 items-center">
            <div>
              <img
                src="@/assets/flags/se.svg"
                width="40px"
                height="40px"
              >
              <input
                v-model="selectedLang"
                type="radio"
                class="mt-1 radio"
                value="SV"
              >
            </div>
            <div>
              <img
                src="@/assets/flags/gb.svg"
                width="40px"
                height="40px"
              >
              <input
                v-model="selectedLang"
                type="radio"
                class="mt-1 radio"
                value="EN"
              >
            </div>
          </div>
        </div> -->
        <p
          v-if="selectedLang === 'EN'"
          class="my-5"
        >
          Enter email
        </p>
        <p
          v-else
          class="my-5"
        >
          Fyll i din epostadress
        </p>
        <div
          class="form-control"
          style="width: 380px"
        >
          <div class="relative">
            <input
              v-model="email"
              type="text"
              placeholder="Email"
              class="w-full pr-16 input input-primary input-bordered text-sm start-input"
              :class="{'input-error': invalidEmail }"
            >
            <button
              class="absolute top-0 right-0 btn btn-primary-small email-button"
              @click="begin()"
            >
              <span v-if="selectedLang === 'EN'">START</span>
              <span v-else>Starta</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      email: '',
      invalidEmail: false,
      selectedLang: 'EN',
      data: ''
    }
  },
  props: {
    campaignId: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    async begin () {
      if (this.email === '') {
        this.invalidEmail = true
        return
      }

      // data contains battery if users email is in users list
      const data = await this.$api.getTeamRatingBattery(this.campaignId, this.email)
      if (data.data === false) {
        this.invalidEmail = true
        return
      }

      if (!data.UserExistsInTeamRatingBattery) {
        this.invalidEmail = true
      } else {
        this.$store.commit('TEAM_RATING/SET_TEAM_RATING_USERS', data.campaign.TeamMembers)
        const ratingUser = data.campaign.TeamMembers.find(item => item.user.email === this.email)

        this.$store.commit('TEAM_RATING/SET_TEAM_RATING_USER', ratingUser)
        this.$store.commit('TEAM_RATING/SET_SELECTED_LANG', this.selectedLang)

        const questionareOptions = {
          traits: ['all', 'traits'].includes(data.campaign.include360Estimation),
          cognitive: ['all', 'cognitive'].includes(data.campaign.include360Estimation)
        }

        // set options and generate questions based on options and language
        this.$store.commit('TEAM_RATING/SET_OPTIONS_AND_QUESTIONS', questionareOptions)

        this.$router.push(`/TeamRating/${this.campaignId}/Question`)
      }
    }
  },
  watch: {
    email () {
      if (this.email === '') {
        this.invalidEmail = false
      }
    }
  }

}
</script>

<style>
.email-button{
  min-height: 4.2rem !important;
  border-bottom-right-radius: 0.5rem ;
  border-top-right-radius: 0.5rem ;
  border-top-left-radius: 0px !important ;
  border-bottom-left-radius: 0px !important ;
}
.start-input{
  padding-top: 1.7em !important;
  padding-bottom: 1.7rem !important;
}
</style>
